import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultPaymentType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_default_payment_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListPaymentType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_list_payment_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPaymentType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_payment_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPaymentType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/add_payment_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editPaymentType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/edit_payment_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePaymentType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/delete_payment_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPaymentTypeOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/payment_type_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
